'use strict';

/**
 * @param {Array} inventories
 * @param {(Object) => String} linkHrefCb
 * @return {DocumentFragment}
 */
export const renderInventorySearchResults = (inventories, linkHrefCb) => {
    const fragment = document.createDocumentFragment();

    inventories.forEach(inventory => {
        const li = document.createElement('li');
        const html = `<a href="${linkHrefCb(inventory)}" data-id="${inventory.id}"></a>`;
        let text = `#${inventory.id} - ${inventory.item.id} ${inventory.item.name}`;

        if (inventory.condition === 'USED') {
            text += ' (ANVÄNT)';
        } else if (inventory.condition === 'NEW') {
            text += ' (NYTT)';
        }

        if (inventory.color) {
            text += ` ${inventory.color.name}`;
        }

        text += `, ${inventory.quantity} st`;

        li.insertAdjacentHTML('afterbegin', html);
        li.firstElementChild.textContent = text;

        if (inventory.color) {
            li.firstElementChild.insertAdjacentHTML(
                'beforeend',
                ` <span class="color-chip" style="background-color: #${inventory.color.code};"></span>`,
            );
        }

        fragment.appendChild(li);
    });

    return fragment;
};

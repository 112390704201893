import { Controller } from 'stimulus'
import { fetch } from '../fetch'
import { hideIcon, showIcon } from '../controllerIcon';

export default class extends Controller {
	static targets = [
		'itemType',
		'itemId',
		'color',
		'conditionUsed',
		'conditionNew',
		'condition',
		'priceGuide',
		'price',
	];

	static values = {
		autoUpdate: {
			type: Boolean,
			default: false,
		},
	};

	connect() {
		if (this.autoUpdateValue) {
			this.update();
		}
	}

	update() {
		if (this.itemType === '' || this.itemId === '') {
			return;
		}

		const query = new URLSearchParams();

		if (this.isConditionNew) {
			query.set('condition', 'N');
		} else if (this.isConditionUsed) {
			query.set('condition', 'U');
		} else {
			return;
		}

		if (this.color !== '') {
			query.set('color', this.color);
		}

		showIcon(this.priceGuideTarget, '⌛️');

		fetch(`/bricklink/item/priceguide/${this.itemType}/${this.itemId}?${query.toString()}`)
			.then(payload => {
				hideIcon(this.priceGuideTarget);
				this.priceGuideTarget.innerHTML = payload;
			})
			.catch(err => {
				showIcon(this.priceGuideTarget, '❌', err.message);
			})
		;
	}

	updateOnDemand(e) {
		e.preventDefault();

		this.update();
	}

	select(e) {
		e.preventDefault();

		const price = parseFloat(e.target.dataset.price);

		this.priceTarget.value = price.toFixed(2);
		this.priceTarget.focus();
	}

	get itemType() {
		return this.itemTypeTarget.value;
	}

	get itemId() {
		return this.itemIdTarget.value;
	}

	get color() {
		return this.colorTarget.value;
	}

	get isConditionUsed() {
		if (!this.hasConditionUsedTarget) {
			return this.conditionTarget.value === 'USED';
		}

		return this.conditionUsedTarget.checked;
	}

	get isConditionNew() {
		if (!this.hasConditionNewTarget) {
			return this.conditionTarget.value === 'NEW';
		}

		return this.conditionNewTarget.checked;
	}
}

import { Controller } from 'stimulus'
import { fetch } from '../fetch'
import { hideIcon, showIcon } from '../controllerIcon'

export default class extends Controller {
	connect() {
		this.element.addEventListener('click', (e) => {
			if (e.target.nodeName !== 'BUTTON') {
				return;
			}

			e.preventDefault();

			this.submit(e.target);
		});
	}

	/**
	 * @param {HTMLButtonElement} button
	 */
	submit(button) {
		showIcon(button, '⌛️');

		const formData = new FormData(this.element);
		formData.append(button.name, button.value);

		fetch(this.element.action, {
			method: 'POST',
			body: formData,
		})
            .then(() => {
                hideIcon(button);
                showIcon(button, '✅');
                setTimeout(() => hideIcon(this.element), 1_000);
            })
            .catch((err) => {
                hideIcon(button);
                showIcon(this.wrapTarget, '❌', err.message);
            });
	}
}

import { Controller } from 'stimulus'
import { fetchDelete, fetchPost } from '../fetch'
import { hideIcon, showIcon } from '../controllerIcon'

export default class extends Controller {
	static targets = [
		'wrap',
		'form',
		'quantity',
		'deliveredQuantity',
		'result',
	];

	connect() {
		if (this.isPacked) {
			this.appendUndoButton();

			return;
		}

		this.insertForm();
	}

	insertForm() {
		const quantity = this.quantityTarget.textContent.trim();
		const html = `
			<form
				data-order-item-pack-target="form"
				data-action="order-item-pack#submit"
			>
				<label>
					<input
						type="number"
						value="${quantity}"
						step="1"
						min="0"
						data-order-item-pack-target="deliveredQuantity"
						aria-label="Ange antal att leverera"
					>
				</label>
				<button type="submit">
					Spara
				</button>
			</form>`
		;

		this.wrapTarget.insertAdjacentHTML('beforeend', html);
	}

	appendUndoButton() {
		const html = `
			<button data-action="order-item-pack#undo" class="button-seamless">
				↩️
			</button>
		`;

		this.resultTarget.insertAdjacentHTML('beforeend', html);
	}

	submit(e) {
		e.preventDefault();

		this.formTarget.hidden = true;
		showIcon(this.wrapTarget, '⌛️');

		const orderId = this.element.dataset.orderId;
		const batchNr = this.element.dataset.batchNr;
		const inventoryId = this.element.dataset.inventoryId;
		const quantity = parseInt(this.deliveredQuantityTarget.value, 10);
		const body = {
			quantity,
		};

		fetchPost(
			`/order/${orderId}/batch/${batchNr}/item/${inventoryId}`,
			body,
			'PUT',
		)
			.then(response => {
				const data = response.data;
				const quantity = parseInt(this.quantityTarget.textContent.trim());

				this.hasQuantityProblem = (data.delivered_quantity < quantity);

				this.wrapTarget.innerHTML = `
					<span
						data-order-item-pack-target="result"
						title="${data.packed_by_name}"
					>
						${this.hasQuantityProblem ? '❗' : ''}
						✅ ${data.delivered_quantity}
					</span>`
				;

				this.appendUndoButton();
				this.isPacked = true;
			})
			.catch(err => {
				this.formTarget.hidden = false;
				showIcon(this.wrapTarget, '❌', err.message);
			})
		;
	}

	undo(e) {
		e.preventDefault();

		showIcon(this.wrapTarget, '⌛️');

		const orderId = this.element.dataset.orderId;
		const batchNr = this.element.dataset.batchNr;
		const inventoryId = this.element.dataset.inventoryId;

		fetchDelete(
			`/order/${orderId}/batch/${batchNr}/item/${inventoryId}`
		)
			.then(response => {
				hideIcon(this.wrapTarget);
				this.wrapTarget.removeChild(this.resultTarget);
				this.insertForm();
				this.isPacked = false;
				this.hasQuantityProblem = false;
			})
			.catch(err => {
				showIcon(this.wrapTarget, '❌', err.message);
			})
		;
	}

	get isPacked() {
		return this.element.dataset.packed === 'true';
	}

	set isPacked(value) {
		this.element.dataset.packed = (value ? 'true' : 'false');

		this.dispatch('packed', {
			target: this.element,
		});
	}

	get hasQuantityProblem() {
		return this.element.dataset.quantityProblem === 'true';
	}

	set hasQuantityProblem(value) {
		this.element.dataset.quantityProblem = (value ? 'true' : 'false');
	}
}

import { Controller } from 'stimulus'

export default class extends Controller {
	static targets = [
		'quantity',
		'quantityChange',
		'decrease',
	];

	connect() {
		this.createDom();
		this.element.classList.add('inventory-quantity-widget');
	}

	createDom() {
		const random = Math.ceil(Math.random() * 10000);
		const inputId = `inventory_quantity_change_${random}`;
		const inputDom = document.createElement('span');
		const buttonDom = document.createElement('span');

		inputDom.innerHTML = `<label for="${inputId}">Ändra antal</label>
			<input
				type="number"
				id="${inputId}"
				data-inventory-quantity-target="quantityChange"
			>`;

		this.element.appendChild(inputDom);

		buttonDom.innerHTML = `<button data-action="inventory-quantity#doIncrease" title="Lägg till antal">+</button>
			<button data-action="inventory-quantity#doDecrease" title="Dra från antal">-</button>`;

		this.element.appendChild(buttonDom);
	}

	doIncrease(e) {
		e.preventDefault();

		if (!this.quantityChange) {
			return;
		}

		this.quantityTarget.value = this.quantity + this.quantityChange;
		this.quantityChangeTarget.value = '';
	}

	doDecrease(e) {
		e.preventDefault();

		if (!this.quantityChange) {
			return;
		}

		this.quantityTarget.value = Math.max(0, this.quantity - this.quantityChange);
		this.quantityChangeTarget.value = '';
	}

	get quantity() {
		const value = parseInt(this.quantityTarget.value, 10);
		if (isNaN(value)) {
			return 0;
		}

		return value;
	}

	get quantityChange() {
		const value = parseInt(this.quantityChangeTarget.value, 10);
		if (isNaN(value)) {
			return 0;
		}

		return value;
	}
}

import { Controller } from 'stimulus'

/**
 * @property {HTMLDivElement[]} itemTargets
 * @property {HTMLButtonElement[]} tabTargets
 * @property {HTMLProgressElement} progressTarget
 * @property {HTMLSpanElement} progressTextTarget
 */
export default class extends Controller {
	static targets = [
		'item',
		'tab',
		'progress',
		'progressText',
	];

	connect() {
		this.insertTabs();
		this.active_tab = 'NOT_PACKED';
		this.filterItems();

		this.initProgress();
	}

	insertTabs() {
		const html = `
			<p>
				<progress data-order-item-pack-tab-target="progress"></progress>
				<span data-order-item-pack-tab-target="progressText"></span>
			</p>

			<ul class="tabs">
				<li>
					<button 
						data-action="order-item-pack-tab#selectTab"
						data-order-item-pack-tab-filter-param="NOT_PACKED"
						data-order-item-pack-tab-target="tab"
					>
						Att göra
					</button>
				</li>
				<li>
					<button 
						data-action="order-item-pack-tab#selectTab"
						data-order-item-pack-tab-filter-param="PACKED"
						data-order-item-pack-tab-target="tab"
					>
						Färdiga
					</button>
				</li>
				<li>
					<button 
						data-action="order-item-pack-tab#selectTab"
						data-order-item-pack-tab-filter-param="PROBLEM"
						data-order-item-pack-tab-target="tab"
					>
						Problem
					</button>
				</li>
			</ul>`
		;

		this.element.insertAdjacentHTML('afterbegin', html);
	}

	itemShouldBeHidden(item) {
		switch (this.active_tab) {
			case 'NOT_PACKED':
				return (item.dataset.packed === 'true');
			case 'PACKED':
				return (item.dataset.packed === 'false');
			case 'PROBLEM':
				return (item.dataset.quantityProblem === 'false');
		}
	}

	filterItems() {
		this.num_packed = 0;
		this.itemTargets.forEach(item => {
			item.hidden = this.itemShouldBeHidden(item);

			if (item.dataset.packed === 'true') {
				++this.num_packed;
			}
		});

		this.updateTabState();
	}

	filterItemsEffect() {
		this.num_packed = 0;
		this.itemTargets.forEach(item => {
			const will_hide = this.itemShouldBeHidden(item);

			if (item.dataset.packed === 'true') {
				++this.num_packed;
			}

			if (!will_hide) {
				item.hidden = false;

				return;
			}

			item.classList.add('will-hide');
			setTimeout(() => {
				item.classList.remove('will-hide');
				item.hidden = true;
			}, 500);
		});

		this.updateProgress();
	}

	selectTab({ params }) {
		this.active_tab = params.filter;
		this.filterItems();
	}

	updateTabState() {
		this.tabTargets.forEach(tab => {
			tab.setAttribute('aria-current', tab.dataset.orderItemPackTabFilterParam === this.active_tab);
		});
	}

	initProgress() {
		this.num_packed = 0;
		this.itemTargets.forEach(item => {
			if (item.dataset.packed === 'true') {
				++this.num_packed;
			}
		});

		this.updateProgress();
	}

	updateProgress() {
		this.progressTarget.max = this.itemTargets.length;
		this.progressTarget.value = this.num_packed;
		this.progressTextTarget.textContent = `${Math.floor(this.num_packed / this.itemTargets.length * 100)}%`;
	}
}

import { Controller } from 'stimulus'
import { fetchPost } from '../fetch'
import { hideIcon, showIcon } from '../controllerIcon'

export default class extends Controller {
	static targets = [
		'cell',
		'quantity',
	];

	static values = {
		hasCheckboxes: {
			type: Boolean,
			default: false,
		},
	};

	connect() {
		this.createDom();
	}

	createDom() {
		this.cellTargets.forEach(element => {
			const quantity = parseInt(element.textContent.trim(), 10);
			element.innerHTML = `<input
				type="number"
				value="${quantity}"
				data-stored-value="${quantity}"
				step="1"
				min="0"
				data-inventory-list-quantity-target="quantity"
			>`;
		});

		let colSpan = 6;

		if (this.hasCheckboxesValue) {
			colSpan = 7;
		}

		const html = `
			<tr>
				<td colspan=${colSpan}></td>
				<td>
					<button type="submit" data-action="inventory-list-quantity#submit">
						Spara
					</button>
				</td>
				<td></td>
			</tr>`;

		this.element
			.querySelector('tr:nth-child(1)')
			.insertAdjacentHTML('afterend', html)
		;
	}

	submit(e) {
		e.preventDefault();

		this.quantityTargets.forEach(element => {
			if (element.value === element.dataset.storedValue) {
				return;
			}

			element.hidden = true;
			showIcon(element.parentNode, '⌛️');

			const inventoryId = element.parentNode.parentNode.dataset.id;
			const quantity = element.value;
			const body = {
				quantity,
			};

			fetchPost(`/inventory/quantity/${ inventoryId }`, body)
				.then(() => {
					element.dataset.storedValue = element.value;

					showIcon(element.parentNode, '✅');
					setTimeout(() => {
						hideIcon(element.parentNode);
						element.hidden = false;
					}, 2_000);
				})
				.catch(err => {
					showIcon(element.parentNode, '❌', err.message);
				})
			;
		});
	}
}

import { Controller } from 'stimulus';
import {
	Chart,
	BarElement,
	LineElement,
	PointElement,
	BarController,
	LineController,
	CategoryScale,
	LinearScale,
	Legend,
	Title,
	Tooltip,
} from 'chart.js';

Chart.register(
	BarElement,
	LineElement,
	PointElement,
	BarController,
	LineController,
	CategoryScale,
	LinearScale,
	Legend,
	Title,
	Tooltip,
);

export default class extends Controller {
	static targets = [
		'data',
	];
	static values = {
		type: String,
		label: String,
	};

	connect() {
		this.datasets = this.dataTargets.map(el => {
			const dd = el.querySelector('dd');

			return {
				month_year: el.querySelector('dt').textContent.trim(),
				value: parseFloat(dd.textContent),
			};
		});

		this.element.querySelector('dl').hidden = true;

		this.canvas = document.createElement('canvas');
		this.element.appendChild(this.canvas);
		this.element.classList.add('sales-chart');

		this.initChart();
	}

	initChart() {
		const months = this.datasets.map(dataset => {
			return dataset.month_year;
		});

		this.chart = new Chart(this.canvas, {
			type: this.typeValue,
			data: {
				labels: months,
				datasets: [
					{
						label: this.labelValue,
						backgroundColor: '#225390',
						borderColor: '#225390',
						tension: 0.1,
						data: this.datasets.map(dataset => {
							return dataset.value;
						}),
					},
				],
			},
			options: {
				maintainAspectRatio: false,
				tooltips: {
					callbacks: {
						label (tooltipItem, data) {
							let label = data.datasets[tooltipItem.datasetIndex].label;

							label += `: ${tooltipItem.value}`;

							return label;
						},
					},
				},
			},
		});
	}
}

import { Controller } from 'stimulus'

export default class extends Controller {
	static targets = [
		'allWrap',
		'all',
		'check',
	];

	lastChecked = undefined;

	connect() {
		const html = `
			<input
				type="checkbox"
				data-check-all-target="all"
				data-action="change->check-all#change"
			/>`
		;

		this.allWrapTarget.insertAdjacentHTML('beforeend', html);

		this.checkTargets.forEach(checkbox => {
			checkbox.addEventListener('click', this.checkBetween.bind(this));
		});
	}

	change() {
		this.checkTargets.forEach(checkbox => {
			checkbox.checked = this.isChecked;
		});

		this.lastChecked = undefined;
	}

	checkBetween(e) {
		const checkboxes = this.checkTargets;
		let inBetween = false;

		if (e.shiftKey && e.target.checked) {
			checkboxes.forEach(checkbox => {
				if (checkbox === e.target || checkbox === this.lastChecked) {
					inBetween = !inBetween;
				}
				if (inBetween) {
					checkbox.checked = true;
				}
			});
		}

		this.lastChecked = e.target;
	}

	get isChecked() {
		return this.allTarget.checked;
	}
}

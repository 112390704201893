'use strict';

/**
 * @param {MouseEvent} e
 */
export const openGenericDialog = (e) => {
    e.preventDefault();

    /** @var {HTMLElement} target */
    const target = e.target;
    const dialogSelector = target.dataset.dialog;

    if (!dialogSelector) {
        throw new Error('Missing data-dialog property');
    }

    const dialogElement = document.querySelector(dialogSelector);
    if (!dialogElement) {
        throw new Error(`Dialog "${dialogSelector}" not found`);
    }

    if (typeof dialogElement.toggle !== 'function') {
        throw new Error(`Dialog "${dialogSelector}" is of wrong constructor`);
    }

    dialogElement
        .parentNode
        .querySelector('summary')
        .click();
};

import { Controller } from 'stimulus'
import { fetch } from '../fetch'
import { hideIcon, showIcon } from '../controllerIcon';
import { renderInventorySearchResults } from '../renderInventorySearchResults';

const searchResultLink = () => '#';

export default class extends Controller {
	static targets = [
		'search',
		'searchIcon',
		'searchResults',
		'addList',
	];

	connect() {
		const html = `
			<p>
				<label for="inventory_storage_add_search">
					<span data-inventory-storage-add-target="searchIcon"></span>
					Sök för att koppla lager:
				</label>
				<input
					type="search"
					id="inventory_storage_add_search"
					value=""
					autocomplete="off"
					data-inventory-storage-add-target="search"
					data-action="keypress->inventory-storage-add#search"
				/>
			</p>

			<ul
				class="select-list"
				data-inventory-storage-add-target="searchResults"
				data-action="click->inventory-storage-add#add"
			></ul>

			<ul
				class="data-list"
				data-inventory-storage-add-target="addList"
				data-action="click->inventory-storage-add#remove"
			></ul>`
		;

		this.element.insertAdjacentHTML('afterbegin', html);
	}

	search(e) {
		if (e.key !== 'Enter') {
			return;
		}

		e.preventDefault();

		this.searchResultsTarget.innerHTML = '';
		if (this.searchTarget.value === '') {
			return;
		}

		showIcon(this.searchIconTarget, '⌛️');

		const query = new URLSearchParams({
			'free_search': this.searchTarget.value,
			'in_storage': 0,
		});

		fetch(`/inventory/search?${query.toString()}`)
			.then(response => {
				const inventories = response.data;

				this.searchResultsTarget.appendChild(
					renderInventorySearchResults(inventories, searchResultLink),
				);

				hideIcon(this.searchIconTarget);
			})
			.catch(err => {
				showIcon(this.searchIconTarget, '❌', err.message);
			})
		;
	}

	add(e) {
		e.preventDefault();

		if (e.target.nodeName !== 'A') {
			return;
		}

		const inventory_id = e.target.dataset.id;

		if (this.addListTarget.querySelector(`[value='${inventory_id}']`) !== null) {
			return;
		}

		const listEl = e.target.parentNode;
		const clone = listEl.cloneNode(true);
		const html = `<input type="hidden" name="inventory[]" value="${inventory_id}" />`;

		clone.insertAdjacentHTML('afterbegin', html);
		clone.insertAdjacentText('afterbegin', '✅ ');

		this.addListTarget.appendChild(clone);
		listEl.remove();
	}

	remove(e) {
		e.preventDefault();

		if (e.target.nodeName !== 'A') {
			return;
		}

		e.target.parentNode.remove();
	}
}

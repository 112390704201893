import { Controller } from 'stimulus'
import { fetchPost } from '../fetch';

/**
 * @property {HTMLDivElement[]} inventoryTargets
 * @property {HTMLDivElement[]} questionTargets
 * @property {HTMLFormElement[]} quantityFormTargets
 * @property {HTMLButtonElement} previousButtonTarget
 * @property {HTMLParagraphElement} celebrationTarget
 * @property {String} searchStorageValue
 */
export default class extends Controller {
	static targets = [
		'inventory',
		'question',
		'quantityForm',
		'previousButton',
		'celebration',
	];

	static values = {
		searchStorage: String,
	};

	connect() {
		this.inventoryIndex = -1;

		this.searchStorageElement = null;
		if (this.searchStorageValue) {
			this.searchStorageElement = document.getElementById(this.searchStorageValue);
		}

		this.inventoryTargets.forEach((inventoryTarget) => {
			inventoryTarget.hidden = true;
		});

		this.insertButtons();
		this.insertCelebration();

		void this.showNextInventory();
	}

	insertButtons() {
		const inventoryHtml = `
			<div data-inventory-stock-taking-target="question">
				<p>
					Stämmer detta?
				</p>

				<p class="button-bar">
					<button data-action="inventory-stock-taking#answerCorrect">
						<span>👍</span> Ja
					</button>
					<button data-action="inventory-stock-taking#answerInCorrect">
						👎 Nej
					</button>
				</p>
			</div>

			<form
				hidden
				data-inventory-stock-taking-target="quantityForm"
				data-action="inventory-stock-taking#submitNewQuantity"
			>
				<p>
					<label>Ange nytt antal</label>
					<input
						type="number"
						step="1"
						min="0"
						name="quantity"
						required
					>
				</p>

				<p class="button-bar">
					<button type="submit">
						<span>💾</span> Spara
					</button>
				</p>
			</form>`
		;

		this.inventoryTargets.forEach((inventoryTarget) => {
			inventoryTarget.insertAdjacentHTML('beforeend', inventoryHtml);

			const input = inventoryTarget.querySelector('input');
			const label = inventoryTarget.querySelector('label');
			const inventoryId = inventoryTarget.dataset.inventoryId;

			input.value = inventoryTarget.dataset.inventoryQuantity
			input.id = `inventory_stock_taking_quantity_${inventoryId}`;
			label.setAttribute('for', input.id);
		});

		const html = `
			<p class="button-bar">
				<button
					data-inventory-stock-taking-target="previousButton"
					data-action="inventory-stock-taking#showPreviousInventory"
				>
					⏮️ Föregående
				</button>

				<a href="/inventory/stock_taking" class="button">
					🅧 Stäng
				</a>
			</p>`
		;

		this.element.insertAdjacentHTML('afterbegin', html);
	}

	insertCelebration() {
		const html = `
			<p data-inventory-stock-taking-target="celebration" hidden class="stock-taking-celebration">
				Allt klart! <strong>🎉</strong>
			</p>`
		;

		this.element.insertAdjacentHTML('beforeend', html);
	}

	/**
	 * @param {MouseEvent} e
	 */
	answerCorrect(e) {
		e.preventDefault();

		this.swapButtonIcon(e.target, '⏳');

		const inventoryElement = this.inventoryTargets.at(this.inventoryIndex);
		const inventoryId = inventoryElement.dataset.inventoryId;

		fetchPost(`/inventory/stock_taking/${inventoryId}`, {})
			.then(() => {
				this.swapButtonIcon(e.target);

				void this.showNextInventory();
			})
			.catch(err => {
				this.swapButtonIcon(e.target, '❌');
				console.error(err.message);
			})
		;
	}

	/**
	 * @param {MouseEvent} e
	 */
	answerInCorrect(e) {
		e.preventDefault();

		this.questionTargets.at(this.inventoryIndex).hidden = true;
		this.quantityFormTargets.at(this.inventoryIndex).hidden = false;
	}

	/**
	 * @param {SubmitEvent} e
	 */
	submitNewQuantity(e) {
		e.preventDefault();

		this.swapButtonIcon(e.target, '⏳');

		const inventoryElement = this.inventoryTargets.at(this.inventoryIndex);
		const inventoryId = inventoryElement.dataset.inventoryId;

		fetchPost(`/inventory/stock_taking/${inventoryId}/quantity`, {
			quantity: parseInt(e.target.elements['quantity'].value, 10),
		})
			.then(() => {
				this.swapButtonIcon(e.target);

				void this.showNextInventory();
			})
			.catch(err => {
				this.swapButtonIcon(e.target, '❌');
				console.error(err.message);
			})
		;
	}

	async showNextInventory() {
		if (this.inventoryIndex >= 0) {
			await this.makeEffekt(this.inventoryTargets.at(this.inventoryIndex), 'will-hide');
		}

		if ((this.inventoryIndex + 1) === this.inventoryTargets.length) {
			this.celebrationTarget.hidden = false;
			this.updatePreviousVisibility();
			this.searchStorageElement?.focus();

			return;
		}

		++this.inventoryIndex;

		await this.makeEffekt(this.inventoryTargets.at(this.inventoryIndex), 'will-show');
		this.updatePreviousVisibility();
	}

	async showPreviousInventory() {
		if (this.inventoryIndex <= 0) {
			this.updatePreviousVisibility();

			return;
		}

		await this.makeEffekt(this.inventoryTargets.at(this.inventoryIndex), 'will-hide');

		if ((this.inventoryIndex + 1) === this.inventoryTargets.length) {
			this.celebrationTarget.hidden = true;
		}

		--this.inventoryIndex;

		this.questionTargets.at(this.inventoryIndex).hidden = false;
		this.quantityFormTargets.at(this.inventoryIndex).hidden = true;

		await this.makeEffekt(this.inventoryTargets.at(this.inventoryIndex), 'will-show');
		this.updatePreviousVisibility();
	}

	/**
	 * @param {HTMLDivElement} element
	 * @param {String} className
	 * @returns {Promise<void>}
	 */
	makeEffekt(element, className) {
		element.classList.add(className);

		if (className === 'will-show') {
			element.hidden = false;
		}

		return new Promise((resolve) => {
			setTimeout(() => {
				element.classList.remove(className);

				if (className === 'will-hide') {
					element.hidden = true;
				}

				element.scrollIntoView(false);

				resolve();
			}, 500);
		});
	}

	updatePreviousVisibility() {
		this.previousButtonTarget.disabled = (this.inventoryIndex <= 0);
	}

	/**
	 * @param {HTMLButtonElement} element
	 * @param {String} icon
	 */
	swapButtonIcon(element, icon = undefined) {
		const iconEl = element.querySelector('span');

		if (icon) {
			element.dataset.originalIcon = iconEl.textContent;
			iconEl.textContent = icon;
		} else {
			iconEl.textContent = element.dataset.originalIcon;
		}
	}
}

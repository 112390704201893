import { Controller } from 'stimulus'

export default class extends Controller {

	/**
	 * @property {MediaQueryList}
	 */
	mql;
	/**
	 * @property {function}
	 */
	mqlChangeCallback;

	connect() {
		this.mql = matchMedia('(max-width: 1000px)');

		this.mqlChangeCallback = this.mediaQueryChanged.bind(this);
		this.mql.addEventListener('change', this.mqlChangeCallback);

		this.mediaQueryChanged();
	}

	disconnect() {
		this.mql.removeEventListener('change', this.mqlChangeCallback);
	}

	mediaQueryChanged() {
		if (this.mql.matches) {
			this.wrapDetails();
		} else {
			this.unWrapDetails();
		}
	}

	wrapDetails() {
		const parent = this.element.parentNode;

		if (parent.nodeName === 'DETAILS') {
			return;
		}

		const details = document.createElement('details');
		const summary = document.createElement('summary');

		summary.textContent = 'Filter';
		details.appendChild(summary);

		parent.insertBefore(details, this.element);

		details.appendChild(this.element);
	}

	unWrapDetails() {
		const details = this.element.parentNode;

		if (details.nodeName !== 'DETAILS') {
			return;
		}

		details.parentNode.insertBefore(this.element, details);
		details.parentNode.removeChild(details);
	}
}

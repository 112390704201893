import { Controller } from 'stimulus'

export default class extends Controller {
	static targets = [
		'toggle',
	];

	isOpen = false;

	connect() {
		const html = `
			<button type="button" data-action="mobile-menu#toggle" class="mobile-menu-button">
				Meny
			</button>`;

		this.toggleTarget
			.insertAdjacentHTML('beforeend', html)
		;

		this.element.setAttribute('aria-expanded', 'false');
	}

	toggle(e) {
		e.preventDefault();

		this.element.setAttribute(
			'aria-expanded',
			(this.isOpen ? 'false' : 'true'),
		);

		this.isOpen = !this.isOpen;
	}
}

import { Controller } from 'stimulus'
import { fetch } from '../fetch'
import { hideIcon, showIcon } from '../controllerIcon';
import { renderInventorySearchResults } from '../renderInventorySearchResults';

export default class extends Controller {
	static targets = [
		'itemType',
		'itemId',
		'itemName',
		'colorLabel',
		'color',
		'conditionUsed',
		'conditionNew',
		'imageWrap',
		'image',
		'existing',
		'existingWarning',
		'existingResult',
		'completeness',
	];

	connect() {
		this.updateItemInfo();
		this.updateColors();
		this.searchExisting();
		this.toggleCompleteness();
	}

	updateItemInfo() {
		this.itemNameTarget.textContent = '';

		if (this.itemType === '' || this.itemId === '') {
			return;
		}

		showIcon(this.itemNameTarget, '⌛️');

		fetch(`/bricklink/item/${this.itemType}/${this.itemId}`)
			.then(payload => {
				hideIcon(this.itemNameTarget);
				this.itemNameTarget.textContent = payload.data.name;
			})
			.catch(err => {
				showIcon(this.itemNameTarget, '❌', err.message);
			})
		;
	}

	updateColors() {
		this.showAllColors();

		if (this.itemType === '' || this.itemId === '') {
			return;
		}

		showIcon(this.colorLabelTarget, '⌛️');

		fetch(`/bricklink/item/color/${this.itemType}/${this.itemId}`)
			.then(payload => {
				hideIcon(this.colorLabelTarget);
				this.showSpecificColors(payload.data.map(color => color.color_id));
			})
			.catch(err => {
				showIcon(this.colorLabelTarget, '❌', err.message);
			})
		;
	}

	showAllColors() {
		this.colorTarget
			.querySelectorAll('option')
			.forEach(option => {
				option.style.display = 'block';
				option.disabled = false;
			})
		;
	}

	showSpecificColors(colorsList) {
		this.colorTarget
			.querySelectorAll('option')
			.forEach(option => {
				if (option.value === '') {
					return;
				}

				const optionValue = parseInt(option.value);

				if (colorsList.indexOf(optionValue) < 0) {
					option.style.display = 'none';
					option.disabled = true;
				}
			})
		;
	}

	updateItemImage() {
		if (this.itemType === '' || this.itemId === '') {
			return;
		}

		showIcon(this.imageWrapTarget, '⌛️');

		fetch(`/bricklink/item/image/${this.itemType}/${this.itemId}/${this.color}`)
			.then(payload => {
				hideIcon(this.imageWrapTarget);
				this.imageTarget.src = payload.data.thumbnail_url;
			})
			.catch(err => {
				showIcon(this.imageWrapTarget, '❌', err.message);
			})
		;
	}

	searchExisting() {
		this.existingWarningTarget.hidden = true;
		this.existingResultTarget.innerHTML = '';

		if (
			this.itemType === ''
			|| this.itemId === ''
			|| !this.hasCondition
		) {
			return;
		}

		showIcon(this.existingTarget, '⌛️');

		const query = new URLSearchParams({
			'item_type': this.itemType,
			'item_id': this.itemId,
			'color_id': this.color,
			'condition': this.condition,
		});

		fetch(`/inventory/search?${query.toString()}`)
			.then(response => {
				const inventories = response.data;

				hideIcon(this.existingTarget);

				if (inventories.length === 0) {
					return;
				}

				this.existingWarningTarget.hidden = false;
				this.existingResultTarget.appendChild(
					renderInventorySearchResults(inventories, inventory => {
						let r = location.pathname;

						if (location.search) {
							r += location.search;
						}

						return `/inventory/edit/${inventory.id}?r=${encodeURIComponent(r)}`;
					}),
				);
			})
			.catch(err => {
				showIcon(this.existingTarget, '❌', err.message);
			})
		;
	}

	toggleCompleteness() {
		this.completenessTarget.hidden = (this.itemType !== 'SET');
	}

	get itemType() {
		return this.itemTypeTarget.value;
	}

	get itemId() {
		return this.itemIdTarget.value;
	}

	get color() {
		return this.colorTarget.value || 0;
	}

	get isConditionUsed() {
		return this.conditionUsedTarget.checked;
	}

	get isConditionNew() {
		return this.conditionNewTarget.checked;
	}

	get hasCondition() {
		return this.isConditionUsed || this.isConditionNew;
	}

	get condition() {
		if (this.isConditionUsed) {
			return 'USED';
		}

		if (this.isConditionNew) {
			return 'NEW';
		}

		return '';
	}
}

'use strict';

import './stimulus'
import '@github/details-menu-element'
import '@github/details-dialog-element'
import { openGenericDialog } from './openGenericDialog'
import '../app.css'

document.addEventListener('DOMContentLoaded', () => {
    document.body.addEventListener('click', e => {
        if (e.target.classList.contains('js-open-dialog')) {
            openGenericDialog(e);
        }
    });
});

'use strict';

export const hideIcon = (element) => {
    const iconElement = element.querySelector('.js-controller-icon');
    if (!iconElement) {
        return;
    }

    iconElement.remove();
};

export const showIcon = (element, icon, title = '') => {
    hideIcon(element);

    element.insertAdjacentHTML(
        'beforeend',
        `<span title="${title}" class="js-controller-icon">${icon}</span>`
    );
};

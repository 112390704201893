import { Controller } from 'stimulus'
import { hideIcon, showIcon } from '../controllerIcon';

export default class extends Controller {
	static targets = [
		'text',
	];

	connect() {
		const html = `
			<button type="button" title="Kopiera" data-action="copy#copy" class="button-seamless">
				📄
			</button>`
		;

		this.element.insertAdjacentHTML('beforeend', html);
	}

	copy() {
		const text = this.textTarget.textContent.trim();

		navigator.clipboard.writeText(text)
			.then(() => {
				showIcon(this.element, '✅');
				setTimeout(() => hideIcon(this.element), 1_000);
			});
	}
}
